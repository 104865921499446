$(window).load(function(){
	$("#loading").fadeOut();
});
/*-----------------------------------------
** page scroll
-----------------------------------------*/
$(function(){
	$('a[href^=#]').click(function() {
		var speed = 400;
		var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var distance = $('header').outerHeight() + 15;
		var position = target.offset().top - distance;
		$('body,html').animate({scrollTop:position}, speed, 'swing');
		return false;
	});

	//var logo = $('#logo');
//	logo.click(function () {
//		$('body,html').animate({
//			scrollTop: 0
//		}, 500);
//		return false;
//	});
});
/*-----------------------------------------
** toggleImg
-----------------------------------------*/
$(function() {
  var $elem = $('.switch');
  var sp = '_sp.';
  var pc = '_pc.';
  var replaceWidth = 640;

  function imageSwitch() {
    var windowWidth = parseInt($(window).width());

    $elem.each(function() {
      var $this = $(this);
      if(windowWidth >= replaceWidth) {
        $this.attr('src', $this.attr('src').replace(sp, pc));
      } else {
        $this.attr('src', $this.attr('src').replace(pc, sp));
      }
    });
  }
  imageSwitch();
  var resizeTimer;
  $(window).on('resize', function() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      imageSwitch();
    }, 200);
  });
});
/*-----------------------------------------
** navigation
-----------------------------------------*/
var wid = $(window).width();
if( wid <= 640 ){
	var naviMenu = $('.menu');
	$(".menu, nav ul li a").click(function(){
        $(".menu1").toggleClass("menuclick1");
        $(".menu2").toggleClass("menuclick2");
        $(".menu3").toggleClass("menuclick3");
    });

	$('.menu').click(function(){
		if ($('nav').css('display') == 'none') {
			$('nav').slideDown();
		} else {
	 		$('nav').slideUp();
	 }
	});

	$('nav ul li a').click(function(){
		if ($('nav').css('display') == 'block') {
			$('nav').slideUp('fast');
		}
	});
	$('nav ul li > ul').css('display','none');

	var navShow = $('section').eq(1);
	var fixTop = navShow.offset().top;
	var value = $(this).scrollTop();
}

/*-----------------------------------------
** pagetop
-----------------------------------------*/
$(document).ready(function(){

    $("#pageTop").hide();

    $(window).on("scroll", function() {

        if ($(this).scrollTop() > 700) {
            $('#pageTop').fadeIn("fast");
        } else {
            $('#pageTop').fadeOut("fast");
        }

     });

    $('#pageTop').click(function () {
        $('body,html').animate({
        scrollTop: 0
        }, 500);
        return false;
     });

});
